import PropTypes from "prop-types"
import React from "react"
import Image from "../image";
import Social from "../social/social";
import style from './intro.module.css'

const Intro = ({siteTitle}) => (
    <div className={style.intro}>
        <div className={style.introLeft}>
            <div className={style.introImg}>
                <Image className={style.introImg}/>
            </div>
        </div>
        <div className={style.introRight}>
            <h2>Walking > Talking</h2>
            <dl>
                <dt>Erasmus University College - Rotterdam</dt>
                <dd>Bsc. Liberal Arts and Science (with honours)</dd>

                <dt>Chinese University Hong Kong</dt>
                <dd>International Asian Studies Program</dd>

                <dt>Higher Federal Technical College - Innsbruck</dt>
                <dd>Electronics and Technical Computer Sciences</dd>
            </dl>
            <p>Always up to build something great. Let's connect:</p>
            <Social/>
        </div>
    </div>
)

Intro.propTypes = {
    siteTitle: PropTypes.string,
}

Intro.defaultProps = {
    siteTitle: ``,
}

export default Intro
