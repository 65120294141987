import React from "react"
import "./social.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faTwitterSquare,
    faGithubSquare,
    faMedium,
    faInstagramSquare,
    faFacebookSquare,
    faLinkedin
} from "@fortawesome/free-brands-svg-icons"

const Social = props => (
    <div className="social-media-wrapper">
        <ul className="social-set">
            <li><a className="sociali" href="http://github.com/Andre741" target="_blank" rel="noreferrer"
                   aria-label="GitHub">
                <FontAwesomeIcon icon={faGithubSquare}/></a></li>
            <li><a className="sociali" href="http://instagram.com/andre.e.g/" target="_blank" rel="noreferrer"
                   aria-label="Instagram">
                <FontAwesomeIcon icon={faInstagramSquare}/></a></li>
            <li><a className="sociali" href="http://www.linkedin.com/in/andre-e-gruber" target="_blank" rel="noreferrer"
                   aria-label="LinkedIn"> <FontAwesomeIcon
                icon={faLinkedin}/></a></li>
            <li><a className="sociali" href="http://twitter.com/werEinemGruber/" target="_blank" rel="noreferrer"
                   aria-label="Twitter">
                <FontAwesomeIcon icon={faTwitterSquare}/></a></li>
            <li><a className="sociali" href="https://medium.com/@andre.gruber" target="_blank" rel="noreferrer"
                   aria-label="Medium">
                <FontAwesomeIcon icon={faMedium}/></a></li>
            <li><a className="sociali" href="http://facebook.com/andre.e.gruber.5" target="_blank" rel="noreferrer"
                   aria-label="Facebook"> <FontAwesomeIcon icon={faFacebookSquare}/></a></li>
        </ul>
    </div>
)


export default Social